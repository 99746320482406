import cn from 'classnames';
import { useTablet } from '@ee-monorepo/shared/utilities/hooks';
import { SliceWrapper } from '@ee-monorepo/prismic/shared/ui';
import s from './page-loader.module.scss';
import { Skeleton } from '../lazy/skeleton';
export interface ContentComponentProps {
  heroBanner: boolean;
}

export const ContentComponent = ({
  heroBanner = false,
}: ContentComponentProps) => (
  <div className="sm:w-full md:w-1/2" data-testid="contentComponent">
    <Skeleton height={32} width={111} animation="wave" />
    <Skeleton height={152} animation="wave" className="w-1/2" />
    <div className="flex flex-wrap mx-0">
      <Skeleton
        height={48}
        width={132}
        animation="wave"
        className={cn('mr-5', s.skeletonBorder)}
      />
      {heroBanner && (
        <Skeleton
          height={48}
          width={132}
          animation="wave"
          className={cn(s.skeletonBorder)}
        />
      )}
    </div>
  </div>
);

export function PageLoader() {
  const isMobile = useTablet();
  const countMap = (value: number) => Array.from(Array(value).keys());

  return (
    <div data-testid="pageLoader">
      <div className="snap-container">
        <div className="snap-row flex justify-between py-4">
          <Skeleton height={40} width={96} animation="wave" />
          <div className={cn('flex flex-wrap items-center', s.desktop)}>
            <Skeleton
              height={40}
              width={69}
              animation="wave"
              className="mr-8"
            />
            <Skeleton
              height={40}
              width={69}
              animation="wave"
              className="mr-8"
            />
            <Skeleton
              height={40}
              width={69}
              animation="wave"
              className="mr-8"
            />
            <Skeleton
              height={40}
              width={69}
              animation="wave"
              className="mr-8"
            />
            <Skeleton
              height={40}
              width={69}
              animation="wave"
              className="mr-8"
            />
            <Skeleton
              height={40}
              width={122}
              animation="wave"
              className="mr-6"
            />
            <Skeleton height={24} width={69} animation="wave" />
          </div>
          <div className={cn('flex flex-wrap items-center', s.mobile)}>
            <Skeleton height={24} width={69} animation="wave" />
          </div>
        </div>
      </div>
      <div className="snap-container">
        <div className="snap-row flex items-center">
          <ContentComponent heroBanner={true} />
          <Skeleton
            height={400}
            animation="wave"
            className="sm:w-full md:w-1/2"
            variant="rectangular"
          />
        </div>
      </div>
      <SliceWrapper className="snap-container">
        <div className="flex flex-wrap flex-col sm:flex-row items-center justify-center ml-0 md:ml-10">
          <ContentComponent heroBanner={false} />
          <div className="sm:w-full md:w-1/2">
            <Skeleton
              height={isMobile ? 400 : 560}
              width={'100%'}
              animation="wave"
              variant="rectangular"
            />
          </div>
        </div>
        <div className="flex flex-wrap flex-col sm:flex-row items-center justify-center ml-0 md:ml-10 pt-10">
          <div className="sm:w-full md:w-1/2">
            <Skeleton
              height={isMobile ? 400 : 560}
              width={'100%'}
              animation="wave"
              variant="rectangular"
            />
          </div>
          <ContentComponent heroBanner={false} />
        </div>
        <div className="flex flex-wrap flex-col sm:flex-row items-center justify-center ml-0 md:ml-10 pt-10">
          <ContentComponent heroBanner={false} />
          <div className="sm:w-full md:w-1/2">
            <Skeleton
              height={isMobile ? 400 : 560}
              width={'100%'}
              animation="wave"
              variant="rectangular"
            />
          </div>
        </div>
      </SliceWrapper>

      <div className="px-4 md:mx-10">
        <div className="flex items-center justify-center">
          <Skeleton height={64} animation="wave" className="w-1/2" />
        </div>
        <div className="flex flex-wrap md:mt-10 mt-4">
          {countMap(4).map((item, index) => (
            <div className="sm:w-full md:w-1/4" key={index}>
              <Skeleton
                height={200}
                animation="wave"
                className="mr-0 md:mr-2 mb-4"
                variant="rectangular"
              />
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center">
          <Skeleton
            height={48}
            width={140}
            animation="wave"
            className={cn(s.skeletonBorder)}
          />
        </div>
      </div>

      <SliceWrapper className="snap-container">
        <div className="snap-row">
          <Skeleton
            height={384}
            animation="wave"
            className="w-full"
            variant="rectangular"
          />
        </div>
        <div className="flex justify-center mt-4 md:mt-12">
          <Skeleton height={70} animation="wave" className="w-1/4" />
        </div>
      </SliceWrapper>
      <div className="flex justify-center">
        <Skeleton height={70} animation="wave" className="w-1/2" />
      </div>
      <SliceWrapper>
        <footer>
          <div className="mx-4 md:mx-10">
            <div
              className={cn(
                'flex flex-wrap md:justify-around border-b pb-2 mb-3',
                s.desktop
              )}
            >
              <div className="md:flex-[0_0_auto] md:w-auto lg:w-1/5 py-4">
                <Skeleton height={40} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
              </div>
              <div className="md:flex-[0_0_auto] md:w-auto lg:w-1/5 py-4">
                <Skeleton height={40} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
              </div>
              <div className="md:col-auto lg:w-1/5 py-4">
                <Skeleton height={40} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
                <Skeleton height={20} width={168} animation="wave" />
              </div>
              <div className="flex md:w-1/2 justify-end">
                <Skeleton
                  variant="rectangular"
                  width={350}
                  height={200}
                  animation="wave"
                />
              </div>
            </div>
            <div className={cn('border-b mb-2', s.mobile)}>
              <Skeleton height={40} animation="wave" className="w-full" />
              <Skeleton height={40} animation="wave" className="w-full" />
              <Skeleton height={40} animation="wave" className="w-full" />
            </div>
            <small className="flex flex-col items-center lg:flex-row lg:justify-between">
              <div>
                <Skeleton width={230} height={20} animation="wave" />
              </div>
              <div>
                <Skeleton width={205} height={20} animation="wave" />
              </div>
              <div className="flex flex-wrap">
                <Skeleton
                  height={20}
                  width={20}
                  animation="wave"
                  className="mr-4"
                  variant="circular"
                />
                <Skeleton
                  height={20}
                  width={20}
                  animation="wave"
                  className="mr-4"
                  variant="circular"
                />
                <Skeleton
                  height={20}
                  width={20}
                  animation="wave"
                  className="mr-4"
                  variant="circular"
                />
                <Skeleton
                  height={20}
                  width={20}
                  animation="wave"
                  className="mr-4"
                  variant="circular"
                />
              </div>
            </small>
          </div>
        </footer>
      </SliceWrapper>
    </div>
  );
}
